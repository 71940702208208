<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card mb-4">
          <div class="dash_card_body">
            <div class="header_section">
              <div>
                <router-link :to="{ name: 'users'}"><i class="glyphicon glyphicon-arrow-left"></i></router-link>
              </div>
            </div>
            <div class="create_items_section">
              <form @submit.prevent="createUser">
                <div v-if="errors">
                  <div class="alert alert-danger">
                    <ul>
                      <li v-for="(err_msg,errindex) in errorMsg.errors" :key="errindex">{{err_msg}}</li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="setting_header">
                      <h2>Create User</h2>
                    </div>
                    <hr>
                    <div class="setting_content">
                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="name">Name:</label>
                          <div class="col-sm-7">
                            <div>
                              <input type="text" class="form-control" v-model="name" @blur="v$.name.$touch">
                            </div>
                            <div class="input_error" v-if="v$.name.$error">
                              <span v-if="v$.name.required.$invalid">Name is required</span>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="email">Email:</label>
                          <div class="col-sm-7">
                            <input type="email" class="form-control" v-model="email" @blur="v$.email.$touch" />
                            <div class="input_error" v-if="v$.email.$error">
                              <span v-if="v$.email.required.$invalid">Email is required</span>
                              <span v-if="v$.email.email.$invalid">Email is Invalid</span>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="dm_consumer_secret">Phone:</label>
                          <div class="col-sm-7">
                            <input type="text" class="form-control" v-model="phone" @blur="v$.phone.$touch" />
                            <div class="input_error" v-if="v$.phone.$error">
                              <span v-if="v$.phone.required.$invalid">Phone is required</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group setting_form_grp">
                        <div class="row">
                          <label class="control-label col-sm-5" for="dm_consumer_secret">Role:</label>
                          <div class="col-sm-7">
                            <div>
                              <select class="form-control" v-model="role" @blur="v$.role.$touch">
                                <option value="">-Select Role-</option>
                                <option v-for="rol in roles" :key="rol.id" :value="rol.id">{{rol.name}}</option>
                              </select>
                            </div>
                            <div class="input_error" v-if="v$.role.$error">
                              <span v-if="v$.role.required.$invalid">Role is required</span>
                            </div>

                          </div>
                        </div>
                      </div>
                      <!--  -->
                    </div>

                    <hr>

                  </div>
                  <!--  -->
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <button type="submit" class="btn btn-primary"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save</span></button>
                  </div>
                </div>
                <!-- end row -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
// import Multiselect from '@vueform/multiselect';
import { required, email } from '@vuelidate/validators';
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
const toast = useToast();
export default {
    components: {
        // Multiselect,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            errors: false,
            status: 1,
            options: [],
            spinner: false,
            vouchers: [],
            password: '',
            roles: [],
            stores: [],
            role: '',
            errorMsg:[],
            selectedStores: [],
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving Changes..."

        }
    },
    mounted: async function () {
        this.getRoles();
        this.getBranches();
    },
    validations() {
        return {
            name: { required },
            phone: { required },
            email: { required, email },
            role: { required },
            status: { required }

        }
    },
    methods: {
        async createUser() {
            this.spinner = true;
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                this.spinner = false;
                return
            }
            this.errors = false;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const baseurl = window.location.origin +"/";
            const url = this.dashBaseUrl + "/create-user";
            const payload = {
                'name': this.name,
                'email': this.email,
                'phone': this.phone,
                'role': this.role,
                'base_url': baseurl,
                'merchant_id':this.merchantID
            }
            this.axios.post(url, payload, config).then((response) => {
                if (response.status === 200) {
                    this.spinner = false;
                    this.$router.push({
                        name: 'users'
                    });
                    toast.success('User created successfully');
                }
            }).catch((error) => {
                this.spinner = false;
                if (error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data
                }else if(error.response.status === 401){
                    this.$router.push({
                        name: 'login'
                    });
                }
            });

        },
        async getRoles() {
            const myArray = [1];
            const arrStr = myArray.join('&' + 'roles_ids[]=');
            const url = `${this.dashBaseUrl}/roles?roles_ids[]=${arrStr}`;
            this.axios.get(url).then((response) => {
                if (response.status === 200) {
                    this.roles = response.data.data;
                }
            }).catch((error) => {
                console.log('roles error', error);
            })
        },
        async getBranches() {
            const url = `${this.baseUrl}//branch?merchant_id=${this.merchantID}`;
            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    const stores = response.data.data;
                    for (let s = 0; s < stores.length; s++) {
                        this.options.push({
                            "value": stores[s].id,
                            "name": stores[s].branch_name
                        })

                    }
                }
            }).catch((err) => {
                console.log('error', err);
            })

        },
    },
    mixins: [ReusableDataMixins]
}
</script>
